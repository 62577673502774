.menu,
.mobile-nav {
    section {
        .item {
            position: relative;
            height: 35px;
            font-size: 18px;
            padding: 0;
            margin-bottom: 8px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: var(--white);
            font-size: 20px;
            width: 100%;
            cursor: pointer;
            font-weight: bold;

            .icon {
                display: flex;
                align-items: center;

                img,
                svg {
                    height: 25px;
                    width: 25px;
                    margin: 0 8px 0 16px;
                }
            }

            .item-bg {
                margin: 0;
                width: 95%;
                height: 35px;
                position: absolute;
                opacity: 0;
                background-color: var(--tertiary);
                z-index: 0;
                transition: opacity 0.5s ease-out;
                border-top-right-radius: 25px;
                border-bottom-right-radius: 25px;

                &.admin {
                    margin-left: -60px;
                }
            }

            &.pro {
                &::after {
                    content: '🔒 PRO';
                    background-color: var(--black);
                    padding: 4px 10px;
                    border-radius: 15px;
                    margin-left: 9px;
                    font-size: 12px;
                    z-index: 1;
                }
            }

            &:hover,
            &.active {
                background-color: var(--tertiary);
                .item-bg {
                    transition: opacity 0.5s ease-in;
                    opacity: 1;
                }
            }

            &:focus {
                outline: 0;
            }

            @media (max-height: 750px) {
                font-size: 16px;
                height: 25px;
            }
        }
    }

    button {
        background-color: transparent;
        padding: 8px 8px;
        margin: auto;
    }
}
