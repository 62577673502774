@mixin step-content-padding {
    padding: 16px;
}

.section-flex {
    display: flex;
    flex-direction: column;

    .content {
        flex: 1;
        max-width: inherit;
        max-height: none;
        margin-top: 80px;
        padding: 0;

        background-color: var(--content-body);

        .custom-graphic {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            img,
            svg {
                max-width: 225px;
                max-height: 125px;
            }
        }
    }
}

@media (min-width: 576px) {
    .section-flex {
        flex-direction: row;
        background-color: #acd7e5;
        box-shadow:
            0 4px 8px 0 rgb(0 0 0 / 20%),
            0 6px 20px 0 rgb(0 0 0 / 19%);
        display: flex;
        border-radius: 16px;
        margin-top: 16px;

        .content {
            border-radius: 0;
            margin: 0;
            box-shadow: none;
            background-color: var(--content-body);
            border-left: 3px solid #e1e1e1;
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
        }
    }
}
