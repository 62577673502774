:root {
    --primary: #acd7e5;
    --secondary: #cf1e4a;
    --tertiary: #f5b43e;
    --quaternary: #394850;

    --primary-accent: #5eb2cd;
    --tertiary-light: #fef8eb;

    --body-light: #acd7e5;
    --body-dark: #263238;
    --text-light: #263238;
    --text-dark: #ffffff;

    --content-body: #f8f8f8f8;
    --content-header: #d6eef6;

    --header: var(--body);

    --black: #2f2f2f;
    --white: #ffffff;

    --error: #e57373;

    font:
        16px 'Poppins',
        sans-serif;

    @media (min-width: 576px) {
        margin: 0 16px;
    }
}

html {
    background-color: #f1f1f1;
}

@import '_generic';
@import '_classes';
@import '_pages';
@import '_menus';
@import '_modals';
