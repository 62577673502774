.form-title {
    color: #f5b43e;
    font-weight: 900;
    margin-bottom: 30px;
    text-align: center;

    @media (max-height: 1024px) {
        margin-bottom: 15px;
    }
}

.auth-button {
    font-size: 14px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    appearance: none;
    border-radius: 10px;
    padding: 10px 16px;
    font-weight: 700;
    cursor: pointer;
    color: #8d8d8d;
    background-color: var(--white);
    border: solid 2px #ebeaeb;
    transition:
        background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
        border 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

    img {
        width: 18px;
        min-width: 18px;
        height: 18px;
        min-height: 18px;
        margin-right: 15px;
    }

    &.fb-button {
        color: white;
        background-color: #1877f2;
    }

    @media (max-height: 1024px) {
        font-size: 12px;
    }
}

.divider-or {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 14px;
    color: #bababa;
    line-height: 8px;
    margin-top: 15px;
    margin-bottom: 15px;

    &::before {
        content: '';
        flex: 1 1 0%;
        margin-top: 3px;
        margin-right: 10px;
        background: #ebeaeb;
        height: 1px;
    }

    &::after {
        content: '';
        flex: 1 1 0%;
        margin-top: 3px;
        margin-left: 10px;
        background: #ebeaeb;
        height: 1px;
    }
}

.overline {
    background-color: var(--tertiary);
    font-weight: bold;
}

.error-inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 2px var(--error);
    color: var(--error);
    margin-top: 30px;
    padding: 10px 15px;
    border-radius: 10px;

    p {
        font-size: 14px;
        color: var(--error);
    }

    svg {
        width: 20px;
        min-width: 20px;
        height: 20px;
        min-height: 20px;
        margin-right: 10px;
        fill: #e57373;
    }
}

.typeform-widget,
.youtube-widget,
.insurance-widget {
    iframe {
        width: 100%;
        height: 70vh;
        border: 0 !important;

        .cKUzLj {
            overflow-y: hidden !important;
        }

        header {
            display: none !important;
        }

        @media (min-width: 576px) {
            height: 80vh;
        }
    }
}

.dashboard {
    width: 100%;
    // height: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .section {
        min-width: 400px;
        max-width: 500px;
        background-color: var(--white);
        // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
        border-radius: 10px;
        padding: 15px;
        margin: 10px;
        display: block;

        @media (max-width: 1199px) {
            max-width: 400px;
            padding: 5px;
            margin: 5px;
        }

        @media (max-width: 825px) {
            max-width: 100%;
            width: 100%;
            padding: 5px;
            margin: 5px;
        }

        &.small {
            min-width: 200px;
            max-width: 250px;
        }
    }
}

.ck.ck-editor__editable_inline {
    border: 1px solid var(--black) !important;
}

.add {
    box-shadow:
        0 2px 4px 0 rgba(0, 0, 0, 0.2),
        0 3px 10px 0 rgba(0, 0, 0, 0.19);
    width: 250px;
    height: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    margin: 15px;
    display: flex;
    flex-direction: column;
    &:hover {
        background-color: rgba(30, 255, 0, 0.24);
    }
}

.vote-actions {
    height: 60px;
    max-height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__like-btn {
        background-color: var(--primary);
    }

    &__unlike-btn {
        background-color: #e1e1e1;
    }

    @media (min-width: 576px) {
        justify-content: center;

        button:nth-child(2) {
            margin-left: 8px;
        }
    }
}

.-center {
    text-align: center;
}

.done-div {
    width: 100%;
    height: 75px;
    // margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    .c-divider-or {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        text-align: center;
        font-size: 14px;
        color: #bababa;
        line-height: 8px;
        // margin: 0 auto 30px;

        .o-button {
            width: 150px;
            height: 50px;
            display: flex;
            align-items: center;
            color: white;
            justify-content: center;
        }
    }
}

.-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.-noflex {
    display: block;
}

.section-title {
    color: var(--tertiary);
    font-size: 24px;
    font-weight: bold;
    display: flex;
    margin-bottom: 5px;

    img,
    svg {
        width: 75px;
        height: 75px;
    }
}
